import React, { Component, useCallback, useState } from 'react'
import type {
  FaceCallback,
  FaceComponentData
} from '@innovatrics/dot-face-auto-capture'
import FaceAutoCapture from './components/FaceAutoCapture'
import PhotoResult from './components/PhotoResult'
import styles from './styles/index.module.css'

import { BASE } from '../../basedata/constants'

interface Props {
  onResponse: (response: string) => void
  token: (token: string) => void
}

function BiometricComponent({ onResponse, token }: Props) {
  const [photoUrl, setPhotoUrl] = useState<string>()

  const [userToken, setUserToken] = React.useState('')

  const [userHasCamera, setUserHasCamera] = useState(true)

  const [blobImage, setBlobImage] = React.useState({})

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  const handlePhotoTaken = (image: Blob, data: FaceComponentData) => {
    const imageUrl = URL.createObjectURL(image)
    setBlobImage(image)
    setPhotoUrl(imageUrl)
  }

  const handleFaceCapturePhotoTaken: FaceCallback = (image, data) => {
    // onResponse('loading')
    handlePhotoTaken(image, data)
  }

  const confirmCapture = (image: any) => {
    onResponse('loading')
    handleFaceCreate(image)
  }

  const handleFaceCreate = async (image: Blob) => {
        setTimeout(() => {
          onResponse('success')
        }, 1000)
  }


  const handleError = useCallback((error: Error) => {
    console.log(error)
    setUserHasCamera(false)
    // onResponse('error')
  }, [])

  React.useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
      setUserToken(window.sessionStorage.getItem('token') + '')
    }
  }, [])

  return (
    <>
      {userHasCamera ? (
        <>
          {' '}
          <h1>
            <span>Vamos lá?!</span>
          </h1>
          <hr />
          <div className={`${styles.app} medium-frame`}>
            <FaceAutoCapture
              onPhotoTaken={handleFaceCapturePhotoTaken}
              onError={handleError}
            />
            {photoUrl && (
              <PhotoResult
                confirmCapture={confirmCapture}
                photoUrl={photoUrl}
                blobImage={blobImage}
              />
            )}
          </div>
        </>
      ) : (
        <div className="no-camera">
          <h1>
            Não achamos <span>sua câmera</span>
          </h1>
          <p>
            Não conseguimos acessar sua câmera para prosseguir. <br />
            Por favor, revise as permissões do seu aplicativo ou tente com outro
            celular
          </p>
          <div className="form-group form-submit">
            <a
              className="btn btn-primary form-control form-control-lg"
              onClick={() => window.location.reload()}
            >
              Recarregar
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default BiometricComponent

import reportWebVitals from './webvitals'

reportWebVitals()
