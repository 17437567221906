import React, { Component, useCallback, useEffect, useState } from 'react'
import './Biometric/OnboardCard.sass'

import selfieIcon from './images/face-icon.svg'
import documentIcon from './images/card-icon.svg'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

function Welcome({ onResponse }: Props) {
  const [photoState, setphotoState] = useState<string>('')

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
  }, [])

  return (
    <div className={`${photoState} card`}>
      <h1 className='text-center'>
      Seja <span>Bem-vindo!</span>
      </h1>
      <hr />
      <p className="text-center">
      Obrigado por participar desta transformação tão grande para a nossa instituição! Em poucas etapas, vamos te ajudar a cadastrar sua biometria facial para acessar o clube de uma forma mais fácil, rápida e tecnológica!
      </p>
    
      <div className="form-group form-submit">
        <a
          className="btn btn-primary form-control form-control-lg"
          onClick={() => onResponse({ step: 'signup' })}
        >
          Avançar
        </a>
      </div>
    </div>
  )
}

export default Welcome