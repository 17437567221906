import React, { Component, useCallback, useEffect, useState } from 'react'
import './UserSignup.sass'
import { BASE } from './../basedata/constants'

import { useForm } from 'react-hook-form'
import LoadingHandler from '../Biometric/StatesComponents/LoadingHandler'

interface Props {
  onResponse: (response: any) => void
}

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const maskPassport = (value: any) => {
  return value.replace(/[^A-Za-z0-9]/g, '').toUpperCase().slice(0, 9);
};

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

function UserSignup({ onResponse }: Props) {
  const [currentStep, setCurrentStep] = useState(1)

  const [accessCode, setAccessCode] = useState('')

  const [registerError, setregisterError] = useState({
    status: false,
    message: ''
  })

  const [formData, setformData] = useState({
    accessCode: '',
    externalId: ''
  })

  function VerifyUser() {
    const {
      register,
      handleSubmit,
      setError,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {

      setCurrentStep(3)
      setTimeout(() => {
       return setCurrentStep(2)
      }, 2000);
    }
    if (currentStep !== 1) {
      return null
    }

    const [isForeign, setIsForeign] = useState(false);

    const handleForeignClick = () => {
      setIsForeign(!isForeign);
    };

    return (
      <>
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <h1>
            Seja <span>bem-vindo!</span>
          </h1>
          <p>
          Por favor, informe sua <strong>matrícula</strong> e número de <strong>CPF</strong> para prosseguir
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                placeholder="Número da carteirinha"
                className="form-control form-control-lg"
                {...register('accessCode')}
              />
              <label className="form-label" htmlFor="accessCode">
                Número da matrícula
              </label>
              {errors.neitherHasSome && (
                <span className="label-error">
                  Você precisa preencher seu número de matrícula
                </span>
              )}
              {registerError.status && (
                <span className="label-error">{registerError.message}</span>
              )}
            </div>
            {/* <div className="form-floating">
              <input
                id="cPF"
                placeholder="CPF"
                className="form-control form-control-lg"
                {...register('cPF')}
              />
              <label className="form-label" htmlFor="cPF">
                CPF
              </label>
            </div> */}

          {!isForeign ? (
            <div className="form-floating">
              <input
                id="cPF"
                placeholder="CPF"
                className="form-control form-control-lg"
                {...register('cPF')}
                onChange={(e) => {
                  e.target.value = maskCPF(e.target.value);
                }}
              />
              <label className="form-label" htmlFor="cPF">
                CPF
              </label>
            </div>
          ) : (
            <div className="form-floating">
              <input
                id="passport"
                placeholder="Passaporte"
                className="form-control form-control-lg"
                {...register('passport')}
                onChange={(e) => {
                  e.target.value = maskPassport(e.target.value);
                }}
              />
              <label className="form-label" htmlFor="passport">
                Passaporte
              </label>
            </div>
          )}
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleForeignClick}
          >
            {isForeign ? 'Sou Brasileiro' : 'Sou Estrangeiro'}
          </button>
      



          </div>
          <div className="form-group form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Próximo"
              onClick={() => clearErrors()}
            />
          </div>
          {/* <a className="help-link" href="#">
          Ajuda
        </a> */}
        </form>
        <ul className="step-counter">
          <li className="active"></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

  function Step2() {
    const {
      register,
      handleSubmit,
      setError,
      reset,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {

      setCurrentStep(3)

      setTimeout(() => {
        return onResponse({
          token: `DEMO`,
          step: 'faceinformation'
        })
      }, 2000);
       
    }

    if (currentStep !== 2) {
      return null
    }

    // useEffect(() => {
    //   setTimeout(() => {
    //     reset({ name: formData.fullname })
    //   }, 3000)
    // }, [formData.fullname])


    const [isForeign, setIsForeign] = useState(false);

    const handleForeignClick = () => {
      setIsForeign(!isForeign);
    };

    return (
      <>
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <p>
            Certo! Agora precisamos que você informe alguns dados para
            validarmos seu cadastro.
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Nome"
                {...(register('fullname'))}
              />
              <label className="form-label" htmlFor="fullname">
                Nome completo
              </label>
              {errors.fullname && (
                <span className="label-error">
                  Você precisa informar um Nome válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Data nascimento"
                {...register('birthday')}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskDate(value)
                }}
              />
              <label className="form-label" htmlFor="birthday">
                Data de nascimento
              </label>
              {errors.birthday && (
                <span className="label-error">
                  Você precisa informar uma data válida
                </span>
              )}
            </div>

            {/* <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="CPF"
                {...register('document')}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskCPF(value)
                }}
              />
              <label className="form-label" htmlFor="document">
                CPF
              </label>
              {errors.document && (
                <span className="label-error">
                  Você precisa informar um Documento válido
                </span>
              )}
            </div> */}

          {!isForeign ? (
              <div className="form-floating">
                <input
                  className="form-control form-control-lg"
                  placeholder="CPF"
                  {...register('document')}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = maskCPF(value);
                  }}
                />
                <label className="form-label" htmlFor="document">
                  CPF
                </label>
                {errors.document && (
                  <span className="label-error">
                    Você precisa informar um Documento válido
                  </span>
                )}
              </div>
            ) : (
              <div className="form-floating">
                <input
                  className="form-control form-control-lg"
                  placeholder="Passaporte"
                  maxLength={9}
                  {...register('passport')}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = maskPassport(value);
                  }}
                />
                <label className="form-label" htmlFor="passport">
                  Passaporte
                </label>
                {errors.passport && (
                  <span className="label-error">
                    Você precisa informar um Passaporte válido
                  </span>
                )}
              </div>
            )}

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="E-mail"
                {...register('email')}
              />
              <label className="form-label" htmlFor="email">
                E-mail
              </label>
              {errors.email && (
                <span className="label-error">
                  Você precisa informar um E-mail válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Celular"
                {...register('celphone', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskPhone(value)
                }}
              />
              <label className="form-label" htmlFor="celphone">
                Celular
              </label>
              {errors.celphone && (
                <span className="label-error">
                  Você precisa informar um telefone válido
                </span>
              )}
            </div>


            <div className="form-group">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleForeignClick}
              >
                {isForeign ? 'Sou Brasileiro' : 'Sou Estrangeiro'}
              </button>
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input form-control-sm"
                {...register('valid')}
                id="valid"
                checked
              // defaultChecked
              />
              <label className="form-check-label" htmlFor="valid">
                Eu aceito os{' '}
                <a href="/bepass_terms.pdf" rel="noreferrer" target="_blank">
                  termos de uso e politica de privacidade
                </a>{' '}
                para o uso BePass
              </label>
              {/* {errors.celphone && (
                <span className="label-error">
                  Você precisa aceitar os termos de uso
                </span>
              )} */}
            </div>
          </div>

          <div className="form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Enviar"
              onClick={() => clearErrors()}
            />
          </div>
        </form>

        <ul className="step-counter">
          <li className="active"></li>
          <li className="active"></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

  return (
    <>
      {/* Verify if users exists */}
      <VerifyUser />
      {/* Sign user up */}
      <Step2 />
      {/* Log user in */}
      {currentStep === 3 && <LoadingHandler />}
    </>
  )
}

export default UserSignup
